import { useEffect, useState } from "react";
import Post from "./post.js";
import PostSkeleton from "../skeletons/post.skeleton.js";
import { requestHandler } from "../../utils/index.ts";

import {  getPosts, getUserPosts } from '../../api/index.ts';
import { useAuth } from "../../context/UserContext.tsx";
export default function PostList({isprofile = false}) {
  const [posts, setPosts] = useState([])
  const [isLoading, setIsLoading] = useState([])
  const { userInfo } = useAuth()
  useEffect(() =>{
    console.log(isprofile);
    if(isprofile){
        requestHandler(async () => await getUserPosts(userInfo._id), setIsLoading, (res) => {
          const { data } = res;
          setPosts(data);
      },
          alert
      );
    }else{
      requestHandler(async () => await getPosts(), setIsLoading,(res)=> {
        const { data } = res;
        setPosts(data);
      },
      alert
      ); 
    }
  },[])



    return (
        <div className="flex flex-col justify-between gap-4 m-auto dark:bg-dark-background w-full pt-4  max-h-[90vh] sm:max-h-[100vh] overflow-y-auto no-scrollbar">
        {isLoading ? <PostSkeleton /> : posts.length > 0 && posts.map(post => (
          <Post key={post._id} {...post} loggedInUser={userInfo?._id} onDeletePost={(id) => {
            setPosts((prev) =>
            prev.filter(post => post._id !== id))
          }} 

          onLikePost={(id) => {
            const index = posts.findIndex((post) => post._id === id);
            const postToUpdate = posts[index];
            postToUpdate.likes.push(userInfo?._id);
            postToUpdate.isLiked = !postToUpdate.isLiked;
            posts.splice(index, 1, postToUpdate);
            setPosts([...posts]);
          }}
          />
        ))}
        </div>
    )
}