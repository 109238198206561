import React from 'react'
import { createContext, useState, useContext, useEffect} from "react";
import socketio from "socket.io-client";
import { LocalStorage } from "../utils/index.ts";



export const SocketContext = createContext({
    socket: null,
    setSocket: () => {},
    getSocket: () => {},
});


const useSocket = () => useContext(SocketContext);

const SocketProvider = function({ children }) {

    const [socket, setSocket] = useState(null);
    
    const getSocket = () => {
    
        const token = LocalStorage.get("token")
        return socketio(process.env.REACT_APP_SOCKET_API_URL, {
            withCredentials: true,
            auth: { token },
        });
    }


    useEffect(() => {
        setSocket(getSocket());
    }, []);
    
    return (
    <SocketContext.Provider value={{ socket, setSocket, getSocket }}>
        {children}
    </SocketContext.Provider>
    );
}

export { SocketProvider, useSocket };