import {  Navigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { requestHandler } from "../../utils/index.ts";

import { createPost } from '../../api/index.ts';
export default function CreatePost() {
    const [content, setContent] = useState('')
    const [files, setFiles] = useState([])
    const [redirect, setRedirect] = useState(false)
    const [ isLoading, setIsLoading ] = useState(false)
    const textareaRef = useRef(null);
    function createNewPost(e) {
        e.preventDefault()

        if(!files || !content) return

        requestHandler(async () => await createPost( content, files[0]), setIsLoading,(res)=> {
            setRedirect(true)
        },
        alert
        ); 
    }
    useEffect(() => {
        textareaRef.current.style.height = "auto";
        textareaRef.current.style.height =  textareaRef.current.scrollHeight + "px";
    }, [content])
    if (redirect) {
        return <Navigate to={'/'} />
    }
    return (
        <form onSubmit={createNewPost} className="w-full h-full sm:h-full sm:w-1/2 m-auto p-10 sm:pt-10 flex flex-col justify-between">
            <div>
            <div className="flex items-center justify-center w-full mb-2">
                <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-dark-background hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                    {files.length == 0 ?  
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                        <p className="text-xs text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                    </div>:
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        <img src={URL.createObjectURL(files[0])} className="w-auto h-60 rounded-lg" />
                    </div>}
                    <input id="dropzone-file" accept="image/*" type="file" className="hidden" onChange={(e) => setFiles(e.target.files)} />
                </label>
            </div>
            <textarea  className="text-theme-text  dark:text-dark-text placeholder:text-theme-text dark:placeholder:text-dark-text font-bold text-2xl  bg-gray-100 dark:bg-dark-background w-full p-2 border border-0 outline-0" placeholder="What's on your mind? Share your thoughts here..." rows="1"  value={content} onChange={e => setContent(e.target.value)} ref={textareaRef}  />
            </div>
            <button className="bg-theme-primary left-0 right-0 mx-auto sm:w-full w-[85vw] flex justify-center hover:bg-primarybtn-700 text-white font-bold my-6 p-2 rounded fixed bottom-10 sm:bottom-0 sm:relative ">
                <div className="flex justify-center">
            {isLoading && 
                <svg className="animate-spin size-5 mx-auto text-white" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
            }
            
                &nbsp;Create Post</div></button>
        </form>
    )
}