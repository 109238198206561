// A class that provides utility functions for working with local storage
import { AxiosResponse } from "axios";
import { APISuccessResponseInterface } from "../Interfaces/api";
import { refreshToken } from "../api/index.ts";

// Check if the code is running in a browser environment
export const isBrowser = typeof window !== "undefined";


export class LocalStorage {
  // Get a value from local storage by key
  static get(key: string) {
    if (!isBrowser) return;
    const value = localStorage.getItem(key);
    if (value) {
      try {
        return JSON.parse(value);
      } catch (err) {
        return null;
      }
    }
    return null;
  }

  // Set a value in local storage by key
  static set(key: string, value: any) {
    if (!isBrowser) return;
    localStorage.setItem(key, JSON.stringify(value));
  }

  // Remove a value from local storage by key
  static remove(key: string) {
    if (!isBrowser) return;
    localStorage.removeItem(key);
  }

  // Clear all items from local storage
  static clear() {
    if (!isBrowser) return;
    localStorage.clear();
  }
}

export const requestHandler = async (
  api: () => Promise<AxiosResponse<APISuccessResponseInterface, any>>,
  setLoading: ((loading: boolean) => void) | null,
  onSuccess: (data: APISuccessResponseInterface) => void,
  onError: (error: string) => void
) => {
  // Show loading state if setLoading function is provided
  setLoading && setLoading(true);
  try {
    // Make the API request
    const response = await api();
    const { data } = response;
    if (data?.success) {
      // Call the onSuccess callback with the response data
      onSuccess(data);
    }
  } catch (error: any) {
    // Handle error cases, including unauthorized and forbidden cases
    
    if ([401, 403].includes(error?.response.status)) {
      try {
        
      const response = await refreshToken();
      const { data } = response;
      //alert()
      
      if (data?.success) {
        LocalStorage.set("token", data.token);
        requestHandler(api, setLoading, onSuccess, onError);
      }else{
        onError(error?.response?.data?.message || "Something went wrong");
        localStorage.clear(); // Clear local storage on authentication issues
        if (isBrowser) window.location.href = "/login"; // Redirect to login page
      }

      } catch (error) {
        onError(error?.response?.data?.message || "Something went wrong");
        localStorage.clear(); // Clear local storage on authentication issues
        if (isBrowser) window.location.href = "/login"; // Redirect to login page
      }

    }else{
      onError(error?.response?.data?.message || "Something went wrong");
    }
  } finally {
    // Hide loading state if setLoading function is provided
    setLoading && setLoading(false);
  }
};

export function getChatUser(participants,loggedInUser,iscurrent) {
  return participants.find(
    (p) => iscurrent ? p._id === loggedInUser?._id : p._id !== loggedInUser?._id
  )
}
