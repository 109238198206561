import './App.css';
import {  Route, Routes } from 'react-router-dom';
import Layout from './components/common/layout';
import PostList from './components/post/postList';
import LoginPage from './pages/auth/loginPage';
import RegisterPage from './pages/auth/registerPage';
import CreatePost from './pages/post/createPost';
import PostPage from './pages/post/PostPage';
import EditPost from './pages/post/EditPost';
import SendChat from './pages/chat/SendChat';
import ProfilePage from './pages/user/profilePage';
import PrivateRoute from "./components/common/PrivateRoute";
import PublicRoute from "./components/common/PublicRoute";

function App() {
  return (
    
      <Routes>
          <Route path={'/'} element={<Layout hideHeaderPaths={['/login', '/register']} /> }>
            <Route index element={<PrivateRoute>  <PostList />  </PrivateRoute>} />
            <Route path={'/create'} element={<PrivateRoute><CreatePost /></PrivateRoute>} />
            <Route path={'/post/:id'} element={ <PostPage />} />
            <Route path={'/post/edit/:id'} element={<PrivateRoute> <EditPost /></PrivateRoute>} />
            <Route path={'/chat'} element={<PrivateRoute><SendChat /></PrivateRoute>} />
            <Route path={'/chat/c/:currentChatid'} element={<PrivateRoute><SendChat /></PrivateRoute>} />
            <Route path={'/user/p/:id'} element={<PrivateRoute><ProfilePage /></PrivateRoute>} />
          </Route>

          <Route path={'/'} >
            <Route path={'/login'} element={ <PublicRoute><LoginPage /></PublicRoute>} />
            <Route path={'/register'} element={<PublicRoute><RegisterPage /></PublicRoute>} />
          </Route>


      </Routes>



  );
}

export default App;
