import { Fragment, useRef, useState, useContext } from 'react'
import { Dialog, Transition, Combobox } from '@headlessui/react'
import { UserContext } from "../../context/UserContext.tsx";
import { CheckIcon,XMarkIcon } from '@heroicons/react/20/solid'
import { requestHandler } from "../../utils/index.ts";

import { startChating } from '../../api/index.ts';

export function NewChatModal({ open, onClose, onSuccess, users }) {
  const { userInfo } = useContext(UserContext);
  const cancelButtonRef = useRef(null)
  const [isopen, setOpen] = useState(false);
  const [selected, setSelected] = useState(users[0])
  const [query, setQuery] = useState('')

  const filteredPeople =
    query === ''
      ? users
      : users.filter((user) =>
            user.username
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )
  function handleOnClose() {
    if (selected)
      startChat();
      onClose();
      setOpen(isopen)
  }
  async function startChat() {

    requestHandler(async () => await startChating({  receivername: selected.username }), null,(res)=> {
      const { data } = res;
      onSuccess(data);
    },
    alert
    ); 

  }
  // if(redirect){
  //   return <Navigate to={'/chat/'+redirect} />
  // }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-visible rounded-lg bg-white dark:bg-dark-secondary text-left shadow-xl transition-all w-full sm:w-full sm:max-w-lg">
              <div className="bg-white dark:bg-dark-secondary sm:flex sm:flex-row-reverse p-2 rounded-t-lg">
                <XMarkIcon className="w-5 h-5 text-theme-text dark:text-dark-text" onClick={handleOnClose}/>
              </div>
                <div className="bg-white dark:bg-dark-secondary px-2 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex flex-col sm:items-start">
                    <div className="mt-2 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-sm font-semibold leading-6 text-theme-text dark:text-dark-text">
                        Find User
                      </Dialog.Title>

                    </div>
                    <div className=" mt-2 w-full px-4 pb-4 ">

                      <Combobox value={selected} onChange={setSelected}>
                        <div className="relative mt-1">
                          <div className="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                            <Combobox.Input
                              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-theme-text focus:outline-none outline-none focus:ring-0 dark:bg-dark-background  dark:border-gray-600 dark:placeholder-gray-400 dark:text-dark-text dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              displayValue={(user) => user.username}
                              onChange={(event) => setQuery(event.target.value)}
                            />
                            {/* <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </Combobox.Button> */}
                          </div>
                          <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                            afterLeave={() => setQuery('')}
                          >
                            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm  dark:bg-dark-background dark:text-dark-text">
                              {filteredPeople.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none px-4 py-2 text-gray-700 dark:text-dark-text">
                                  Nothing found.
                                </div>
                              ) : (
                                filteredPeople.map((person) => (
                                  <Combobox.Option
                                    key={person._id}
                                    className={({ active }) =>
                                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-theme-primary dark:bg-gray-600 text-theme-text dark:text-dark-text' : 'text-theme-text dark:text-dark-text'
                                      }`
                                    }
                                    value={person}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                                            }`}
                                        >
                                          {person.username}
                                        </span>
                                        {selected ? (
                                          <span
                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-theme-text' : 'text-teal-600'
                                              }`}
                                          >
                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Combobox.Option>
                                ))
                              )}
                            </Combobox.Options>
                          </Transition>
                        </div>
                      </Combobox>
                    </div>

                  </div>
                </div>

                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6 dark:bg-dark-accent rounded-b-lg">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-theme-primary px-3 py-2 text-sm font-semibold text-theme-text shadow-sm hover:bg-theme-primary sm:ml-3 sm:w-auto"
                    onClick={() => handleOnClose()}
                  >
                    Chat
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}