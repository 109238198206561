import { useState, useEffect, useContext, useRef } from "react";
import { useSocket } from "../../context/SocketContext.tsx";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/UserContext.tsx";
import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { PaperAirplaneIcon } from '@heroicons/react/24/solid'
import { useNavigate } from "react-router-dom";
import { ChatList } from "../../components/chat/chatList.js";
import { NewChatModal } from "../../components/chat/NewChatModal.js";
import { LocalStorage, getChatUser, requestHandler } from "../../utils/index.ts";
import { NewChatBtn } from "../../components/chat/newChatBtn.js";
import { BackBtn } from "../../components/common/backBtn.js";

import { MessageItem } from "../../components/chat/messageItem.js";
import  ChatListSkeleton  from "../../components/skeletons/chatList.skeleton.js";
import  ChatPageSkeleton  from "../../components/skeletons/chatPage.skeleton.js";

import { getChatMessages, getChat, sendMessages, fetchUsers, } from '../../api/index.ts';

const CONNECTED_EVENT = "connected";
const DISCONNECT_EVENT = "disconnect";
const JOIN_CHAT_EVENT = "joinChat";
const NEW_CHAT_EVENT = "newChat";
const TYPING_EVENT = "typing";
const STOP_TYPING_EVENT = "stopTyping";
const MESSAGE_RECEIVED_EVENT = "messageReceived";
// const LEAVE_CHAT_EVENT = "leaveChat";
// const UPDATE_GROUP_NAME_EVENT = "updateGroupName";

export default function SendChat() {

  const { socket } = useSocket();
  const { userInfo } = useAuth();

  const navigate = useNavigate();
  const currentChat = useRef({ current: null });
  const { currentChatid } = useParams();
  const [message, setMessage] = useState('');
  //const [chat, setChat] = useState(false); // To store user's chats
  const [chats, setChats] = useState([]); // To store user's chats
  const [messages, setMessages] = useState([]); // To store user's chats
  const [istyping, setTyping] = useState(false); // To store user's chats
  const [selfTyping, setSelfTyping] = useState(false);
  const [isConnected, setIsConnected] = useState(false); // To store user's chats
  const messagesEndRef = useRef(null);
  const [openAddChat, setOpenAddChat] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [mobile, setMobile] = useState(window.innerWidth <= 640);

  const [isChatLoading, setIsChatLoading] = useState(false);
  const [IsMessagesLoading, setIsMessagesLoading] = useState(false);

  const typingTimeoutRef = useRef(null);

  const onConnect = () => {
    setIsConnected(true);
  };

  const onDisconnect = () => {
    setIsConnected(false);
  };

  function goBack() {
    navigate('/chat');
  }

  async function getMessages() {
    if (!socket) return alert("Socket not available");

    setUnreadMessages(
      unreadMessages.filter((msg) => msg.chat !== currentChat.current?._id)
    );

    requestHandler(async () => await getChatMessages(currentChat.current?._id), setIsMessagesLoading,(res)=> {
      const { data } = res;
      setMessages(data);
    },
    alert
    ); 
  }
  // async function getChat() {
  //   fetch(process.env.REACT_APP_API_URL + '/api/v1/chat/getchat', {
  //     method: 'POST',
  //     headers: { 'Content-Type': 'application/json' },
  //     body: JSON.stringify({ _id: currentChat.current._id }),
  //     credentials: 'include',
  //   }).then(response => {
  //     response.json().then(chat => {

  //       setChat(chat.data[0]);

  //     })
  //   })
  // }

  async function getChats() {
    requestHandler(async () => await getChat(), setIsChatLoading,(res)=> {
      const { data } = res;
      setChats(data);
    },
    alert
    ); 

  }

  async function sendMessage(e) {
    e.preventDefault()
    //socket.emit(STOP_TYPING_EVENT, {id,message,userInfo});
    if (!currentChat.current?._id || !socket || !message) return;

    // Emit a STOP_TYPING_EVENT to inform other users/participants that typing has stopped
    socket.emit(STOP_TYPING_EVENT, currentChat.current?._id);

    requestHandler(async () => await sendMessages({chat:currentChat.current._id, content:message}), null,(res)=> {
      const { data } = res;
      setMessages((prev) => [data, ...prev]); // Update messages in the UI
      updateChatLastMessage(currentChat.current?._id || "", data); // Update the last message in the chat
      setSelfTyping(false);
      
    },
    alert
    ); 

    //setChats([...chats, {chatID,message,userInfo}]);
    setMessage('')
  }

  const updateChatLastMessage = (chatToUpdateId, message) => {
    // Search for the chat with the given ID in the chats array
    const chatToUpdate = chats.find((chat) => chat._id === chatToUpdateId);

    // Update the 'lastMessage' field of the found chat with the new message
    chatToUpdate.lastMessage = message;

    // Update the 'updatedAt' field of the chat with the 'updatedAt' field from the message
    chatToUpdate.updatedAt = message?.updatedAt;

    // Update the state of chats, placing the updated chat at the beginning of the array
    setChats([
      chatToUpdate, // Place the updated chat first
      ...chats.filter((chat) => chat._id !== chatToUpdateId), // Include all other chats except the updated one
    ]);
  };

  function handleOnSocketStopTyping(chatId) {
    if (chatId !== currentChat.current._id) return;
    setTyping(false);
    //scrollToBottom()
  }
  function handleOnSocketTyping(chatId) {
    if (chatId !== currentChat.current._id) return;
    setTyping(true);
  }
  function onNewChat(chat) {
    setChats((prev) => [...prev, chat]);
  }
  function onMessageReceived(message) {
    //alert("here")
    if (message?.chat !== currentChat.current?._id) {
      // If not, update the list of unread messages
      setUnreadMessages((prev) => [ message, ...prev]);
    } else {
      // If it belongs to the current chat, update the messages list for the active chat
      setMessages((prev) => [message, ...prev]);
    }

    // setMessages(messages => [...messages, message]);
    setTyping(false);
    setTimeout(() => {
      //scrollToBottom()
    }, 100);
  }
  function handleMessageChange(e) {
    //alert("here")
    setMessage(e.target.value);
    if (!socket || !isConnected) return;
    // Check if the user isn't already set as typing
    if (!selfTyping) {
      // Set the user as typing
      setSelfTyping(true);

      // Emit a typing event to the server for the current chat
      socket.emit(TYPING_EVENT, currentChat.current._id);
    }

    // Clear the previous timeout (if exists) to avoid multiple setTimeouts from running
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    // Define a length of time (in milliseconds) for the typing timeout
    const timerLength = 3000;

    // Set a timeout to stop the typing indication after the timerLength has passed
    typingTimeoutRef.current = setTimeout(() => {
      // Emit a stop typing event to the server for the current chat
      socket.emit(STOP_TYPING_EVENT, currentChat.current._id);
      // Reset the user's typing state
      setSelfTyping(false);
      //scrollToBottom()
    }, timerLength);
  }

  const getUsers = async () => {
    requestHandler(async () => await fetchUsers(), null,(res)=> {
      const { data } = res;
      setUsers(data);
      setOpenAddChat(true);
    },
    alert
    ); 
  }

  // const scrollToBottom = () => {
  //   setTimeout(() => {
  //     messagesEndRef.current?.scrollIntoView({
  //       block: "nearest",
  //       inline: "center",
  //       behavior: "smooth",
  //       alignToTop: false
  //     });
  //   }, 100);
  // }

  // if (istyping) {
  //   scrollToBottom()
  // }

    // This useEffect handles the setting up and tearing down of socket event listeners.
    useEffect(() => {
      // If the socket isn't initialized, we don't set up listeners.
      if (!socket) return;
  
      // Set up event listeners for various socket events:
      // Listener for when the socket connects.
      socket.on(CONNECTED_EVENT, onConnect);
      // Listener for when the socket disconnects.
      socket.on(DISCONNECT_EVENT, onDisconnect);
      // // Listener for when a user is typing.
      socket.on(TYPING_EVENT, handleOnSocketTyping);
      // // Listener for when a user stops typing.
      socket.on(STOP_TYPING_EVENT, handleOnSocketStopTyping);
      // // Listener for when a new message is received.
      socket.on(MESSAGE_RECEIVED_EVENT, onMessageReceived);
      // // Listener for the initiation of a new chat.
      socket.on(NEW_CHAT_EVENT, onNewChat);
      // // Listener for when a user leaves a chat.
      // socket.on(LEAVE_CHAT_EVENT, onChatLeave);
      // // Listener for when a group's name is updated.
      // socket.on(UPDATE_GROUP_NAME_EVENT, onGroupNameChange);
  
      // When the component using this hook unmounts or if `socket` or `chats` change:
      return () => {
        // Remove all the event listeners we set up to avoid memory leaks and unintended behaviors.
        socket.off(CONNECTED_EVENT, onConnect);
        socket.off(DISCONNECT_EVENT, onDisconnect);
        socket.off(TYPING_EVENT, handleOnSocketTyping);
        socket.off(STOP_TYPING_EVENT, handleOnSocketStopTyping);
        socket.off(MESSAGE_RECEIVED_EVENT, onMessageReceived);
        socket.off(NEW_CHAT_EVENT, onNewChat);
        // socket.off(LEAVE_CHAT_EVENT, onChatLeave);
        // socket.off(UPDATE_GROUP_NAME_EVENT, onGroupNameChange);
      };
  
      // Note:
      // The `chats` array is used in the `onMessageReceived` function.
      // We need the latest state value of `chats`. If we don't pass `chats` in the dependency array,
      // the `onMessageReceived` will consider the initial value of the `chats` array, which is empty.
      // This will not cause infinite renders because the functions in the socket are getting mounted and not executed.
      // So, even if some socket callbacks are updating the `chats` state, it's not
      // updating on each `useEffect` call but on each socket call.
    }, [socket, chats]);
  
    useEffect(() => {
  
  
      getChats();
      // // Retrieve the current chat details from local storage.
      const _currentChat = LocalStorage.get("currentChat");
      // If there's a current chat saved in local storage:
      if (_currentChat && socket) {
        //   // Set the current chat reference to the one from local storage.
        currentChat.current = _currentChat;
        //   // If the socket connection exists, emit an event to join the specific chat using its ID.
        socket?.emit(JOIN_CHAT_EVENT, currentChat.current._id);
        // Fetch the messages for the current chat.
        getMessages();
        //getChat();
      }
      // An empty dependency array ensures this useEffect runs only once, similar to componentDidMount.
    }, []);


  return (
    <>
      <NewChatModal
        open={openAddChat}
        onClose={() => {
          setOpenAddChat(false);
        }}
        onSuccess={() => {
          getChats();
        }}
        users={users}
      />

      <div className={"w-full h-full justify-between items-stretch flex gap-2 flex-shrink-0 divide-x divide-gray-100 dark:divide-neutral-800"+ (!mobile ? " max-h-[calc(100vh_-_50px)] sm:max-h-[100vh]" : " ")}>
        {!currentChatid && (
          <div className={"bg-primary-100 dark:bg-dark-secondary rounded-md p-2"+ (mobile ? " w-full" : " w-1/4")}>
            <div className="flex justify-between">
              <BackBtn />
              <NewChatBtn getUsers={getUsers} />
            </div>
            <div className="mt-2">
            {isChatLoading ? <ChatListSkeleton /> :
              <ul role="list" className="">
                {chats && chats.map((chat) => (
                  <ChatList key={chat?._id} onClick={(chat) => {
                    if (currentChat?.current?._id === chat?._id && !mobile) return
                    LocalStorage.set('currentChat', chat);
                    currentChat.current = chat;
                    setMessage('');
                    getMessages()
                    //getChat();
                    
                    mobile && navigate('/chat/c/'+chat?._id);
                  }} chat={chat} unreadCount={unreadMessages.filter((msg) => msg.chat === chat?._id).length} />
                ))}
              </ul>
            }
            </div>
          </div>
        )}
        {(!mobile || currentChatid) && (
        <div className={"flex flex-col justify-start gap-2"+ (mobile && currentChatid ? " w-full" : " w-3/4")}>
          {IsMessagesLoading ? <ChatPageSkeleton /> : (currentChat?.current?._id) && (
            <>
              <div className="flex h-12 sm:h-auto sticky justify-between bg-primary-100 dark:bg-dark-background dark:text-dark-text border-b border-gray-100 dark:border-neutral-800">
                <div className="flex user-info p-2">
                  <BackBtn />
                  <img onClick={() => navigate('/user/p/'+getChatUser(currentChat?.current?.participants, userInfo, false)?._id)}  className="size-8 sm:size-12 flex-none object-cover rounded-full bg-gray-50" src={getChatUser(currentChat?.current?.participants, userInfo, false)?.avatar?.url} alt="" />
                  <h3 className="other-username pl-2 font-semibold">{currentChat?.current?._id && currentChat?.current?.participants.find(
                    (p) => p._id !== userInfo?._id
                  )?.username}</h3>
                </div>
                <div>
                  <InformationCircleIcon className="size-6 m-2" />
                </div>
              </div>
              <div className="flex flex-col-reverse w-full h-[calc(100vh_-_130px)] sm:h-[calc(100vh_-_100px)] overflow-y-auto no-scrollbar">
                {messages.length > 0 && messages.map(message => (

                  <MessageItem message={message} key={message?._id} isMine={message?.sender?._id === userInfo?._id} />

                ))}

                <div className={"flex gap-2  mt-2 justify-start transition-all ease-in-out duration-300 " + (istyping ? "opacity-100 min-h-[50px] mb-20" : " opacity-0 h-0 min-h-0 overflow-hidden")}>
                  <div className="pl-4 pr-4 pt-2 rounded-3xl text-theme-text bg-indigo-400 rounded-bl">
                    <div className="typing p-1 mt-2">
                      <div className="dot rounded-full h-[7px] w-[7px]  mr-[4px] bg-white"></div>
                      <div className="dot rounded-full h-[7px] w-[7px]  mr-[4px] bg-white"></div>
                      <div className="dot rounded-full h-[7px] w-[7px]  mr-[4px] bg-white"></div>
                    </div>
                  </div>
                </div>

                <div ref={messagesEndRef} />
              </div>

              <div className="fixed sm:sticky  bottom-0 sm:flex p-2 w-full bg-theme-background dark:bg-dark-background">
                <form className="flex border-2 w-full rounded-full border-gray-300 dark:border-gray-600" onSubmit={sendMessage}>
                  <input type="text"
                    placeholder="Chat...."
                    value={message}
                    className=" w-full rounded-full p-2 pl-5  overflow-hidden outline-none border-none resize-none line dark:bg-dark-background  dark:border-gray-600 dark:placeholder-gray-400 dark:text-dark-text dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    onChange={(e) => handleMessageChange(e)} />
                  <button className="bg-white-500 size-[50px] rounded-full text-theme-text font-bold"><PaperAirplaneIcon className="text-theme-primary m-2 hover:text-primarybtn-600" /></button>
                </form>
              </div>
            </>
          )}
          {!(currentChat?.current?._id) && (
            <div className="w-full flex-col margin-auto h-full flex justify-center">
              <div className="w-full flex justify-center">
                <div className="w-1/3 flex flex-col justify-center items-center text-center">
                  <p className="text-3xl p-2 mb-2 text-theme-text dark:text-dark-text font-bold">Find Your Friends and Start Chatting</p>
                  <NewChatBtn getUsers={getUsers} />
                </div>
              </div>
            </div>
          )}
        </div>
        )}
      </div>
    </>
  )
}