import moment from "moment";
import { UserIcon } from '@heroicons/react/24/outline'
export  function MessageItem({isMine, message}) {

  return (
    <div key={message._id} className={"flex gap-2  h-auto mt-2 transition-all ease-in-out duration-300 " + (isMine ? 'justify-end' : 'justify-start')}>
    <div className={"flex flex-col p-4 pb-1 max-w-[70vw] rounded-3xl text-white " + (isMine ? 'bg-blue-400 rounded-br' : 'bg-indigo-400 rounded-bl ')}>
      <div className={"font-normalt text-lg "+(isMine ? 'bg-blue-400 rounded-br text-right' : 'bg-indigo-400 rounded-bl')}>{message.content}</div>
      <div className={"text-xs"+(isMine ? ' text-right' : '')}> {moment(message.updatedAt).add("TIME_ZONE", "hours").fromNow(true)}{" "}
    ago</div>
      </div>
    <img className={"info rounded-full object-cover text-white size-[40px] mt-3 text-lg " + (isMine ? ' mr-2 ' : ' ml-2 order-first')} src={message.sender?.avatar?.url} ></img>
    </div>
  )
}