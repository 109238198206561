import { UserContext } from "../../context/UserContext.tsx";
import { useContext, useState } from "react";
import { getChatUser } from "../../utils/index.ts";
export function ChatList({ chat, onClick, unreadCount }) {
  const { userInfo } = useContext(UserContext);
  const [mobile, setMobile] = useState(window.innerWidth <= 640);
  return ( 

    <li onClick={() => onClick(chat)} className="flex justify-between gap-x-6 h-20 py-5 bg-theme-background rounded-xl cursor-pointer hover:bg-gray-100 dark:bg-dark-background dark:hover:bg-gray-600 mt-1">
      <div className="flex min-w-0 gap-x-4 ml-4">
        <img className="size-8 sm:size-12 object-cover flex-none rounded-full bg-gray-50" src={getChatUser(chat?.participants, userInfo, false)?.avatar?.url} alt="" />
        <div className={"min-w-0 flex-auto sm:block" + (mobile ? " " : " hidden")}>
          <p className="text-sm font-semibold leading-6 text-theme-text dark:text-gray-100">{getChatUser(chat?.participants, userInfo, false)?.username}</p>
          <p className="mt-1 truncate text-xs leading-5 text-gray-500 dark:text-gray-300">{chat?.lastMessage?.content}</p>
        </div>
      </div>
      <div className={"shrink-0 sm:flex sm:flex-col sm:items-end" + (mobile ? " " : " hidden")}>
        <p className="text-sm leading-6 text-theme-text">{chat?.role}</p>
        {unreadCount > 0 && (
          <div className="mt-1 flex w-10 items-center gap-x-1.5">
            <div className="flex-none size-6  rounded-full bg-emerald-500/20 p-1">
              <div className="size-4 rounded-full bg-emerald-500 text-white text-[10px] align-middle font-medium leading-3 pl-1.5 pt-0.5" >
                {unreadCount}
              </div>
            </div>
          </div>
        )}
      </div>
    </li>


  )

}