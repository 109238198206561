import { useEffect,useState, useContext } from "react"
import { Link, useParams } from "react-router-dom"
import { UserContext } from "../../context/UserContext.tsx";

import { requestHandler } from "../../utils/index.ts";

import { getUserPost } from '../../api/index.ts';

export default function PostPage() {
    const {id} = useParams();
    const [postInfo,setPostInfo] = useState({})
    const {userInfo} = useContext(UserContext);
    useEffect(() => {

        requestHandler(async () => await getUserPost( id),null,(res)=> {
            const { data } = res;
            setPostInfo(data)
        },
        alert
        ); 
    },[])
    if(!postInfo) {
        return ''
    }
    return (
        <div className="post-page">
            <h1>{postInfo.title}</h1>
            <time>{new Date(postInfo.createdAt).toDateString()}</time>
            <div className="author">By @{postInfo?.author?.username}</div>
            {userInfo?._id === postInfo.author?._id && (
                <div className="edit-row"> 
                <Link to={`/edit/${id}`}>
                    <a className="edit-btn"> 
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                    </svg>
                    Edit Post
                    </a>
                </Link>
                </div>
            )}
            <div className="image">
                <img src={postInfo.cover}></img>
            </div>
            <div className="content">
                <div dangerouslySetInnerHTML={{__html: postInfo.content}}/>
            </div>
        </div>
    )
}