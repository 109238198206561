import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { HeartIcon, ChatBubbleBottomCenterTextIcon, TrashIcon, PencilIcon } from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { useState, Fragment } from 'react';
import { deletePost, likePost } from '../../api/index.ts';
import { requestHandler } from '../../utils/index.ts';
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function Post({ _id, content, cover, createdAt, author, isLiked , loggedInUser, onDeletePost, onLikePost }) {

    const [points, setPoints] = useState({ x: 0, y: 0 });

    const [showMore, setShowMore] = useState(false);

    const menuOpenClose = (e) => {
        setPoints({ x: e.pageX-50, y: e.pageY+15 })
    }

    const handleDelete = async (id) => {
        requestHandler(async () => await deletePost(id), null, () => {
            onDeletePost(id);
        },
            alert
        );
    }
    const handleLike = async (id) => {
        requestHandler(async () => await likePost(id), null, () => {
            onLikePost(id);
        },
            alert
        );
    }

    return (
        <div className=" rounded  bg-primary-100 dark:bg-dark-background lg:w-2/5 md:w-3/4 w-4/5 m-auto">
            <div className='w-full h-auto flex justify-between'>
                <Link to={`/user/p/${author?._id}`}>
                    <div className="flex gap-2 p-1">
                        <img className="size-8 p-1 object-cover rounded-full" src={author?.avatar?.url} alt="user photo" />
                        <div className='text-theme-text dark:text-gray-100 font-bold cursor-pointer hover:font-semibold'>{author?.username}</div>
                    </div>
                </Link>
                {loggedInUser === author?._id &&
                    <Menu as="div" >
                        <Menu.Button onClick={(e) => menuOpenClose(e)}><div className='text-theme-text dark:text-gray-100'><EllipsisHorizontalIcon className="size-8 p-1" /></div></Menu.Button>
                        <Transition
                            as={Fragment}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Menu.Items style={{ top: points.y, left: points.x }} className="fixed z-10 w-28 rounded-md bg-theme-background shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-dark-accent dark:text-dark-text">
                                <Menu.Item as="div" className="px-1 py-1">
                                    {({ active }) => (
                                        <div
                                            onClick={() => handleDelete(_id)}
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900 dark:bg-zinc-800 dark:text-dark-text"
                                                    : "text-gray-700 dark:text-dark-text",
                                                "block w-full px-4 py-2 text-left text-sm rounded-md cursor-pointer"
                                            ) + " flex gap-2"}
                                        >
                                            <TrashIcon className="size-5 text-red-500" />
                                            <div>Delete</div>
                                        </div>
                                    )}
                                </Menu.Item>
                                <Menu.Item as="div" className="px-1 py-1">
                                    {({ active }) => (
                                        <Link
                                            to={`/post/edit/${_id}`}
                                            className={classNames(
                                                active
                                                    ? "bg-gray-100 text-gray-900 dark:bg-zinc-800 dark:text-dark-text"
                                                    : "text-gray-700 dark:text-dark-text",
                                                "block w-full px-4 py-2 text-left text-sm rounded-md cursor-pointer"
                                            ) + " flex gap-2"}
                                        >
                                            <PencilIcon className="size-5 text-green-500" />
                                            <div>Edit</div>
                                        </Link>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Transition>
                    </Menu>
                }

            </div>
            <img className="w-full" src={cover} alt="Sunset in the mountains" />
            <div className="flex p-1 h-10 justify-between text-theme-text dark:text-gray-100">
                <div className="flex gap-2 ">
                    <HeartIcon onClick={() => handleLike(_id)} className={"size-8 p-1 sm:hover:opacity-50 hover:opacity-100 transition-all cursor-pointer" + (isLiked ? " text-red-500 fill-red-500 animate-bounce" : "")} />
                    <ChatBubbleBottomCenterTextIcon className="size-8 p-1 hover:fill-theme-text hover:dark:fill-dark-text" />
                </div>
                <div>
                    <div className=" dark:text-gray-400">
                        <time>{format(new Date(createdAt), 'MMM d, yyyy HH:mm')}</time>
                    </div>
                </div>
            </div>
            <div className="px-2 py-2 ">
                <p  className={"text-gray-700 text-sm dark:text-gray-400 break-words whitespace-pre-line  overflow-hidden " + (showMore ? "h-auto" : "max-h-10") }>
                    { content }
                </p>
                <div className="text-blue-200 text-sm cursor-pointer" onClick={() => setShowMore(!showMore)}>{ showMore ? "...less" : "..more" }</div>
            </div>
             
        </div>
    )
}