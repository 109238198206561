// Import necessary modules and utilities
import axios from "axios";
import { LocalStorage } from "../utils/index.ts";

// Create an Axios instance for API requests
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  timeout: 120000,
});

// Add an interceptor to set authorization header with user token before requests
apiClient.interceptors.request.use(
  function (config) {
    // Retrieve user token from local storage
    const token = LocalStorage.get("token");
    // Set authorization header with bearer token
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// API functions for different actions
const loginUser = (data: { username: string; password: string }) => {
  return apiClient.post("/users/login", data);
};

const registerUser = (data: {
  email: string;
  password: string;
  username: string;
}) => {
  return apiClient.post("/users/register", data);
};

const logoutUser = () => {
  return apiClient.post("/users/logout");
};

const refreshToken = () => {
  //console.log("refreshing token");
  return apiClient.post("/users/refresh-token");
}

const getChatMessages = (chatId: string) => {
  return apiClient.get(`/chat/getmessages/${chatId}`);
};
const getUserPosts = (userId: string) => {
  return apiClient.get(`post/getuserpost/${userId}`);
};


const getChat = () => {
  return apiClient.get(`/chat/chats`);
};

const sendMessages = (data: {chat: string, content: string}) => {
  return apiClient.post(`/chat/sendmessage`, data);
};

const fetchUsers = () => {
  return apiClient.get("/users/fetchusers");
}
const getUser = (userId) => {
  return apiClient.get(`/users/p/${userId}`);
}
const getPosts = () => {
  return apiClient.get("/post/getpost");
}

const startChating = (data: {
  receivername: string
}) => {
  return apiClient.post("/chat/joinchat",data);
}

const createPost = ( content, file) => {
  const data = new FormData();
  data.set('content', content);
  data.set('coverImage', file);
  return apiClient.post("/post/createpost",data);
}

const updatePost = ( content, file, id) => {
  const data = new FormData();

  data.set('content', content);
  data.set('id', id);
  if(file) {
      data.set('coverImage', file);
  }
  return apiClient.put("/post/updatepost",data);
}

const getUserPost = (id) => {
  return apiClient.get(`/post/p/${id}`);
}

const deletePost = (id) => {
  return apiClient.delete(`/post/delete/${id}`);
}

const uploadAvatar = (file) => {
  const data = new FormData();
  data.set('avatarImage', file);
  return apiClient.post("/users/updateprofileImage",data);
}

const likePost = (id) => {
  return apiClient.post(`/post/like/${id}`);
}

// Export all the API functions

export {
  loginUser,
  registerUser,
  logoutUser,
  getChatMessages,
  getChat,
  sendMessages,
  fetchUsers,
  startChating,
  getPosts,
  getUserPosts,
  getUser,
  createPost,
  updatePost,
  getUserPost,
  refreshToken,
  deletePost,
  uploadAvatar,
  likePost
}