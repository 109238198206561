import { UserPlusIcon } from "@heroicons/react/24/outline";
export function NewChatBtn({ getUsers }) {
    return (
        <button
            onClick={getUsers}
            className="flex w-auto h-[40px] bg-theme-primary hover:bg-theme-primary text-white py-2 px-4 rounded"
        >
            <UserPlusIcon
                className="w-5 h-5 text-white font-bold mt-1 mr-2"
                aria-hidden="true"
            />{" "}
            <span className="hidden sm:block">New Chat</span>
        </button>
    );
}
