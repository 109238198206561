import { Outlet, useLocation  } from "react-router-dom";
import Header from "./header";

export default function Layout({hideHeaderPaths}){

    const path = useLocation().pathname
    return (
        <main className="flex justify-stretch bg-primary-100 h-screen dark:bg-dark-background" >
            
           {hideHeaderPaths.includes(path) ? null : <Header />}
           <div className=" w-full sm:w-[85vw]">
            <Outlet />
           </div>
        </main>
    )
}