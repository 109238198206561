import React from 'react'
import { useContext } from 'react'
import { createContext, useEffect, useState } from "react";
import { LocalStorage, requestHandler } from "../utils/index.ts";
import { useNavigate } from "react-router-dom";
import { Loader } from '../components/common/loader';
import { useSocket } from '../context/SocketContext.tsx';
import { loginUser, registerUser, logoutUser } from '../api/index.ts';
export const UserContext = createContext<{
    userInfo: Object | null,
    setUserInfo: Function,
    getUserInfo: Function,
    logout: Function,
    login: Function,
    regester: Function,
}>({
    userInfo: null,
    setUserInfo: () => {},
    getUserInfo: () => {},
    logout: () => {},
    login: () => {},
    regester: () => {},
});

const useAuth = () => useContext(UserContext);

const  UserContextProvider = function({ children }) {
    const [userInfo, setUserInfo] = useState(null);
    const [IsLaoding, setIsLaoding] = useState(false);
    const navigate = useNavigate();
    
    const { setSocket, getSocket } = useSocket();

    useEffect(() => {
        setIsLaoding(true)
        const _user = LocalStorage.get("user") || '';
        if (_user?._id) {
            setUserInfo(_user);
        }else{
            setUserInfo(_user)
        }
        setIsLaoding(false)
    },[])

    function getUserInfo() {
        return userInfo
    }

    function logout() {
        requestHandler(async () => await logoutUser(), setIsLaoding,()=> {
            //const _USER = null
            console.log("hehehe");
            
            setUserInfo(null)
            LocalStorage.clear(); 
            navigate('/login')
        },
        alert
        ); 
    }

    const  login = async (data: {
        username: string;
        password: string;
      }) => {
        requestHandler(async () => await loginUser(data), setIsLaoding,(res)=> {
            const { data } = res;
            setUserInfo(data.user)
            LocalStorage.set("user", data.user);
            LocalStorage.set("token", data.token);
            setSocket(getSocket());
            setIsLaoding(false)
            navigate('/')
        },
        alert
        );  
    }

    const regester = async  (data:{
        username: string;
        password: string;
        email: string;
    }) =>{
        requestHandler(async () => await registerUser(data), setIsLaoding,()=> {
            alert('Registration successful!')
        },
        alert
        ); 
    }
    return (
    <UserContext.Provider value={{ userInfo, setUserInfo, logout, regester, login, getUserInfo}}>
        {IsLaoding ? <Loader /> :children}
    </UserContext.Provider>
    );
}


export { UserContextProvider, useAuth }