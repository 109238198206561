// Import required modules and types from React and react-router-dom libraries
import React, {  useEffect } from "react";
import { Navigate, useNavigate, useLocation   } from "react-router-dom";
import { Loader } from "./loader.js";

// Import authentication context for retrieving user and token information
import { useAuth } from "../../context/UserContext.tsx";

// Define a PrivateRoute component that wraps child components to ensure user authentication
const PublicRoute =  ({ children }) => {
  // Destructure token and user details from the authentication context
  const { userInfo,getUserInfo } =  useAuth()


  // console.log(getUserInfo());
  // if (userInfo !== null) return <Loader />
  // console.log(userInfo);
  // If there's no token or user ID, redirect to the login page
  if (userInfo?._id) return <Navigate to={'/'}  replace />;
  // console.log("here123");
  // If authenticated, render the child components
  return children;
};

// Export the PrivateRoute component for use in other parts of the application
export default PublicRoute;
