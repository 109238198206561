import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext.tsx";
export default function LoginPage() {
    const [logingdata, setData] = useState({
        username:'',
        password:''
    });
    const { login } = useContext(UserContext);
    const handleLogin = async () => await login(logingdata);
    const handleDataChange =  (name) => (e) => {
        setData({
            ...logingdata,
            [name]: e.target.value,
        });
    };

    return (
        <section className="bg-gray-50 dark:bg-dark-secondary h-[100vh] w-full dark">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-[90vh] lg:py-0">

            <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-dark-background dark:border-gray-700">
                <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1 className="text-xl font-bold leading-tight tracking-tight text-theme-text md:text-2xl dark:text-dark-text">
                        Sign in to your account
                    </h1>
                    <div className="space-y-4 md:space-y-6">
                        <div>
                            <label htmlFor="email" className="block mb-2 text-sm font-medium text-theme-text dark:text-dark-text">Your email</label>
                            <input onChange={handleDataChange('username')} value={logingdata.username} className="bg-gray-50 border border-gray-300 text-theme-text sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-dark-background dark:border-gray-600 dark:placeholder-gray-400 dark:text-dark-text dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required=""/>
                        
                        </div>
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-theme-text dark:text-dark-text">Password</label>
                            <input type="password" onChange={handleDataChange('password')} value={logingdata.password} placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-theme-text sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-dark-background dark:border-gray-600 dark:placeholder-gray-400 dark:text-dark-text dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-start">
                                <div className="flex items-center h-5">
                                    <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-dark-background dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                                </div>
                                <div className="ml-3 text-sm">
                                    <label htmlFor="remember"  className="text-gray-500 dark:text-gray-300">Remember me</label>
                                </div>
                            </div>
                            <a href="#" className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500">Forgot password?</a>
                        </div>
                        <button type="button" onClick={handleLogin} className="w-full text-white bg-theme-primary hover:bg-primarybtn-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>
                        <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                            Don’t have an account yet? <Link to="/register" className="font-medium text-primary-600 hover:underline dark:text-primary-500">Sign up</Link>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </section>
    )
}