import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { CameraIcon } from '@heroicons/react/24/solid'
//import Post from "../../components/post/post";
import PostList from '../../components/post/postList';
import { useAuth } from "../../context/UserContext.tsx";
import { LocalStorage } from "../../utils/index.ts";
import PostSkeleton from "../../components/skeletons/post.skeleton";
import { requestHandler } from "../../utils/index.ts";

import { uploadAvatar, getUser } from '../../api/index.ts';
export default function ProfilePage() {

    const [userposts, setuserPosts] = useState([])
    const [user, setuser] = useState([])
    const [file, setFile] = useState([])
    const userId = useParams()?.id;
    const { setUserInfo, userInfo } = useAuth();
    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingAvatar, setIsLoadingAvatar] = useState(false)
    useEffect(() => {

        requestHandler(async () => await getUser(userId), null, (res) => {
            const { data } = res;
            setuser(data);
        },
            alert
        );

    }, [])

    function uploadHandler(e) {
        requestHandler(async () => await uploadAvatar( e.target.files[0]), setIsLoadingAvatar, (res) => {
            const { data } = res;
            setFile(e.target.files);
            setUserInfo(data);
            LocalStorage.set('user', data);
        },
            alert
        );
    }
    return (
        <div className="flex w-full flex-col-reverse devide divide-x sm:flex-row sm:">
            <div className="h-full sm:w-3/4 w-full max-h-[100vh] overflow-y-auto flex flex-col gap-2 no-scrollbar">
                    <PostList isprofile={true} />
            </div>
            <div className="sm:h-full sm:w-1/4 w-full h-1/4">
                <div className="w-full sm:h-1/3 flex justify-center items-center place-items-center sm:flex-col">
                    <div>
                        <label htmlFor="avatar-file" className="group sm:size-36 size-24 sm:p-0 p-2  grid hover:bg-gray-300 hover:bg-opacity-10 place-items-center rounded-full">
                            {isLoadingAvatar ? <svg class="animate-spin size-16 mx-auto text-theme-primary " viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg> :
                                <>
                                    <img className="w-full h-full rounded-full object-cover col-start-1 row-start-1 group-hover:blur-sm" src={file.length > 0 ? URL.createObjectURL(file[0]) : user?.avatar?.url} />
                                    <CameraIcon className="size-16 text-gray-400 hidden group-hover:block opacity-60 z-10 col-start-1 row-start-1" />
                                </>
                            }

                        </label>
                        <input id="avatar-file" type="file" className="hidden" onChange={(e) => uploadHandler(e)} />
                    </div>
                    <div className="w-full sm:text-center text-left dark:text-dark-text pl-2">{user?.username}</div>
                </div>
            </div>
        </div>
    )
}