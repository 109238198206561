import { useEffect, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext.tsx";
import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChatBubbleOvalLeftIcon, PlusCircleIcon, HomeModernIcon, Bars2Icon } from "@heroicons/react/24/solid";
import { LocalStorage } from "../../utils/index.ts";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Header() {
  const { setUserInfo, userInfo, logout } = useContext(UserContext);
  const [userAvatar, setUserAvatar] = useState(userInfo?.avatar?.url);
  const [isDark, setisDark] = useState((LocalStorage.get('isDark') === null) ? true : LocalStorage.get('isDark'));
  const currentPath = useLocation().pathname;
  useEffect(() => {
    setUserAvatar(userInfo?.avatar?.url);
  }, [userInfo]);
  useEffect(() => {
    //LocalStorage.get('isDark') != '' && setisDark(LocalStorage.get('isDark'))
    LocalStorage.set('isDark', isDark)
    if (isDark) {
      document.body.classList.add('dark')
    } else {
      document.body.classList.remove('dark')
    }
  }, [isDark]);


  const switchTodark = () => {
    setisDark(!isDark)
    

  }

  return (
    <header className={"bg-theme-background box-shadow-lg shadow-md dark:bg-dark-accent w-[100vw] h-[50px] fixed bottom-0 sm:w-[15vw] sm:h-[100vh] sm:sticky  " + ((useLocation().pathname.startsWith('/chat/c/')) ? "hidden sm:block" : '')}>
      <nav
        className="mx-1 w-full sm:mx-auto flex sm:flex-col max-h-7xl h-full justify-between p-1 sm:p-4 lg:px-8 border-t  sm:border-r border-neutral-200 dark:border-neutral-600"
        aria-label="Global"
      >
        <div className="flex">
          <div href="#" className="m-1 p-1">
            <span className="sr-only">Your Company</span>
            <Link to="/">
              <img
                className="h-6  sm:h-10 w-auto"
                src="/logo.png"
                alt=""
              />
            </Link>
          </div>
        </div>
        {userInfo?.username && (
          <>
            <div className="flex gap-2 sm:flex-col flex-1 height-full justify-center sm:justify-start text-gray-700 dark:text-dark-text ">
              <Link to="/">
                <div className="flex p-1 h-auto sm:h-12">
                  <div className="flex justify-center sm:size-10 "><HomeModernIcon className="size-8 pt-1" /></div>
                  <div className={"hidden sm:block pl-4 align-middle  " + ((currentPath === '/') ? "font-bold" : '')}>
                  <div >Home</div>
                  </div>
                </div>
              </Link>
              <Link to="/chat">
                <div className="flex p-1 h-auto sm:h-12">
                  <div className="flex justify-center sm:size-10"><ChatBubbleOvalLeftIcon className="size-8 pt-1" /></div>
                  <div className={"hidden sm:block pl-4 align-middle " + ((currentPath === '/chat') ? "font-bold" : '')}>
                   <span >Chat</span>
                  </div>
                </div>
              </Link>
              <Link to="/create">
                <div className="flex p-1 h-auto sm:h-12">
                  <div className="flex justify-center sm:size-10"><PlusCircleIcon className="size-8 pt-1" /></div>
                  <div className={"hidden sm:block pl-4 align-middle " + ((currentPath === '/create') ? "font-bold" : '')}>
                  <span >Create</span>
                  </div>
                </div>
              </Link>
              <Link to={`/user/p/${userInfo?._id}`}>
                <div className="flex p-1 h-auto sm:h-12">
                  <div className="flex justify-center rounded-full sm:size-10 p-0.5 sm:p-0">
                    <img className="size-8 p-1 object-cover rounded-full" src={userAvatar} alt="user photo" />
                  </div>

                  <div className={"hidden sm:block pl-4 align-middle " + ((currentPath.startsWith('/user/p/')) ? "font-bold" : '')}>
                  <span >Profile</span>
                  </div>
                </div>
              </Link>
            </div>

          </>)}
        <Menu as="div" className="flex relative inline-block text-left justify-start ">
          <div className="p-2 sm:p-0">
            <Menu.Button className="size-8 sm:size-10 inline-flex bg-primary-100e  text-sm font-semibold text-gray-900 shadow-sm hover:bg-gray-50 dark:hover:bg-primary-600 dark:bg-dark-accent  dark:text-dark-text">
              <Bars2Icon className="w-full h-full" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute bottom-14 right-2 sm:left-0 z-10 mt-2 w-56 origin-bottom-left rounded-md bg-theme-background shadow-lg ring-1 ring-black ring-opacity-5  focus:outline-none dark:bg-dark-accent dark:text-dark-text z-20">
              <div className="py-1 divide-gray-200 dark:divide-gray-800">
                <Menu.Item>
                  {({ active }) => (
                    <button onClick={switchTodark}
                      className="h-12 w-12 rounded-lg p-2 hover:bg-gray-100 dark:hover:bg-gray-700">
                      <svg className="fill-violet-700 block dark:hidden" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"></path>
                      </svg>
                      <svg className="fill-yellow-500 hidden dark:block" fill="currentColor" viewBox="0 0 20 20">
                        <path
                          d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                          fillRule="evenodd" clipRule="evenodd"></path>
                      </svg>
                    </button>
                  )}
                </Menu.Item>
                {userInfo?.username ? (
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      onClick={logout}
                      className={classNames(
                        active
                          ? "bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-dark-text"
                          : "text-gray-700 dark:text-dark-text",
                        "block w-full px-4 py-2 text-left text-sm"
                      )}
                    >
                      Sign out
                    </Link>
                  )}
                </Menu.Item>
                  ) : (
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to="/login"
                      className={classNames(
                        active
                          ? "bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-dark-text"
                          : "text-gray-700 dark:text-dark-text",
                        "block w-full px-4 py-2 text-left text-sm"
                      )}
                    >
                      Log in
                    </Link>
                  )}
                </Menu.Item>
                  )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </nav>
    </header>
  );
}
